.navbar-widht {
    height: 81px;
    align-items: center;
    padding: 0 80px !important;
    background-color: #fff;
    z-index: 999;
}

.navbar-search input {
    box-sizing: border-box;
    width: 417px;
    height: 44px;
    background: #F5F5F5 !important;
    border-radius: 0 6px 6px 0;
    border: none;
    outline: none;
}

.navbar-search::before {
    position: relative;
    content: '';
    height: 44px;
    width: 25px;
    border-radius: 6px 0 0 6px;
    background: #F5F5F5 !important;
}

.profil-content .span1 {
    font-size: 16px !important;
}

.profil-content .span2 {
    font-size: 14px !important;
}

.profil-content img {
    width: 47px !important;
    height: 47px !important;
}

.ochish{
    background-color: #ffffff;
    top: 70%!important;
    left: 95%!important;
    font-size: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.ochish li p{
    cursor: pointer;
}
.yopish{
    top: 70%!important;
    left: 95%!important;
    height: 0;
    overflow: hidden;
}