.button-end {
    margin-right: 5rem !important;
}

.title {
    font-weight: 500 !important;
    font-size: 36px !important;
}

.page-bg {
    margin: 0 2rem 0 0;
    padding: 1.2rem 1.2rem 1.2rem 1.2rem !important;
    min-height: 70vh;
    border-radius: 16px 16px 0px 0px !important;
    background-color: #f5f5f5;
}

.buyurtmachi-form {
    height: 429px;
    background: #FFFFFF;
    border-radius: 16px;
}

.form-input-buyurtmachi {
    width: 770px !important;
    height: 44px !important;
    background: #F5F5F5 !important;
    border: 1px solid #DBDBDB !important;
    border-radius: 6px !important;
}

.form-label {
    font-weight: 400 !important;
    font-size: 20px !important;
}

.form-div {
    width: 90% !important;
}

.slect-buyurtma{
    width: 80% !important;
    height: 44px !important;
    padding: 0 8px !important;
    background-color:  #F5F5F5 !important;
    border: 1px solid #DBDBDB !important;
    border-radius: 6px !important;
}
          
          
.title-1{
    font-size: 36px !important;
}