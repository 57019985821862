.oq-button {
    background: #ECF0FF !important;
    min-width: 166px!important;
    height: 40px;
    transition: .5s all !important;
    font-size: 0.95rem!important;
}

.yashil-button {
    background: #5030E5 !important;
    border-radius: 6px;
    width: 100px;
    height: 40px;
    color: white!important;
    transition: .5s all !important;
}
.button-bg:hover{
    box-shadow: 1px 2px 2px #111 !important;    
}