.bxl-radius {
    cursor: pointer;
    transition: .5s all !important;
    border-radius: 16px !important;
    padding: 3px 0 !important;
}

.bxl-radius:hover {
    background: #ECF0FF;
}

.trash-bg {
    color: #F24E1E;
}

.trash-bg:hover {
    color: #111 !important;
}

.edit-pencil:hover {
    color: #F24E1E !important;
}
.bxl-title{
    font-weight: 400 !important;
    font-size: 20px !important;
}