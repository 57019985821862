.qurilma{
    width: 91%;
    margin-left: 4%;
}
.qurilmaNom{
    border-radius: 16px 16px 0px 0px;
}
.bi-plus{
    height: 40px;
    width: 40px;
    font-size: 40px;
    border: 2px dotted gray;
    border-radius: 10px;
}

.qurilmaIn{
    border-radius: 0px 0px 4px 4px;
}

.AddDevice{
    width: 95%;
    margin: auto;
    min-height: 75vh;
    border-radius: 16px;
}
.AddDevice div{
    border-radius: 16px;
}

.qurilmainput{
    width: 23%!important;
    color: #787486;
}
.param{
    border-radius: 0!important;
}
.deviceName{
    width: 69%!important;
}

/* Qurilma qo'shish uchun */


.AppDevice{
    width: 95%;
    margin: auto;
}
.devcontent{
    border-radius: 16px;
}

.form-field{
    width: 100%;
}

.form-field label, h2{
    margin: 0;
    font-size: 26px;
    font-weight: 500;
    color: black;
}
.items{
    margin-left: 9.7vw;
    padding-left: 1%!important;
}

.items input{
    width: 12.5%!important;
    padding: 7px;
}

.services input{
    height: 25px;
    width: 10%;
    padding: 7px;
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(220, 220, 220);
}
.items input{
    height: 25px;
    width: 10%;
    padding: 7px;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(220, 220, 220);
}

.form-field  input:focus{
    border: 1px solid rgb(220, 220, 220);
}

.devcontent button{
    background: #ECF0FF;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    padding: 0px 5px!important;
    align-self: flex-start;
    margin-top: 0.5rem;
    color: rgb(0, 208, 255);
    border: none;
    margin: 0px 1px;
}


