.button-end1 {
    margin-right: 4rem !important;
}

.title {
    font-weight: 500 !important;
    font-size: 36px !important;
}

.page-bg1 {
    margin: 0 2rem 0 0;
    padding: 1rem 1.2rem 1.2rem 1.2rem !important;
    min-height: 75vh;
    border-radius: 16px 16px 0px 0px !important;
    background-color: #f5f5f5;
}
.bg-light,.border-bajaruvchi{
    border-radius: 16px;
}

.bajaruvchi-form{
    background: #fff;
    border-radius: 16px;
}
.form-input-bajaruvchi {
    width: 80% !important;
    padding: 0 8px !important;
    height: 44px !important;
    background: #F5F5F5 !important;
    border: 1px solid #DBDBDB !important;
    border-radius: 6px !important;
}

.form-label {
    font-weight: 400 !important;
    font-size: 20px !important;
}

.title-1{
    font-size: 36px !important;
}
.bajaruvchi-form {
    width:92% !important;
}
