*{
    list-style: none;
    text-decoration: none!important;
    padding: 0!important;
}
.asosiyMenyu{
    overflow: hidden;
    /* position: fixed; */
}
.asosiyMenyu ul{
    overflow: hidden;
    width: 250px;
    margin-left: -10px;
    padding-left: 5px!important;
}

.asosiyMenyu ul li span{
    color: #787486!important;
}
.asosiyMenyu ul .li{
    width: 200px!important;
    background-color: #F8F9FA;
    color: #787486;
    border-radius: 10px;
    margin-top: 2px;
    padding: 20px 10px 12px 5px!important;
}

.asosiyMenyu span i{
    font-size: 1.4rem;
}
.asosiyMenyu span{
    font-size: 15px;
    margin-right: 15px;
}

.aloqa{
    width: 85%;
    text-align: center;
    border-radius: 16px;
    margin: auto;
}
.aloqa p{
    line-height: 10px;
    color: #787486;
}
.oq-button {
    width: 166px;
    height: 40px;
    padding: 10px 15px!important;
    border-radius: 4px;
}
.oq-button i{
    font-size: 30px;
    margin-bottom: -15px!important;
}
.container-fluid{
    display: flex;
}
.menyu{
    width: 17%;
    min-height: 100vh;
    position: relative;
    transition: all 0.5s ease;
}
.content{
    width: 83%;
    transition: all 0.5s ease;
}
.logo{
    height: 80px;
}
.logotip{
    margin-left: 10px;
    font-size: 30px;
    text-indent: 15px;
    display: flex;
}

.togl{
    position: absolute;
    right: 5%;
    font-size: 25px;
    color: #787486;
}
.bla{
    width: 40px;
    height: 40px;
    background-image: url('../../assets/touchId.png');
    background-size: 30px;
    margin-left: 5%!important;
    background-repeat: no-repeat;
}
@media (max-width: 1000px){
    .asosiyMenyu ul .li{
        width: 100%important;
        background-color: #F8F9FA;
        color: #787486;
        border-radius: 10px;
        margin-top: 2px;
        padding: 12px 10px 12px 5px!important;
    }
    .asosiyMenyu span i{
        font-size: 100%;
    }
    .asosiyMenyu ul{
        margin-left: -15px;
    }
    .linkText{
        
        font-size: 0.9rem!important;
    }
}

@media (min-width: 1370px){
    .asosiyMenyu ul .li{
        width: 100%important;
        background-color: #F8F9FA;
        color: #787486;
        border-radius: 10px;
        margin-top: 2px;
        padding: 20px 10px 12px 5px!important;
    }
    .asosiyMenyu ul{
        width: 350px;
    }
    .asosiyMenyu span i{
        font-size: 180%;
        margin-left: 3%;
    }
    .linkText{
        margin-left: 20px!important;
        font-size: 1.2rem!important;
    }
}

@media (min-width: 1870px){
    .asosiyMenyu ul{
        width: 400px;
    }
    .asosiyMenyu ul .li{
        width: 100%important;
        background-color: #F8F9FA;
        color: #787486;
        border-radius: 10px;
        margin-top: 2px;
        padding: 20px 10px 12px 5px!important;
    }
    .asosiyMenyu span i{
        font-size: 230%;
        margin-left: 3%;
    }
    .linkText{
        font-size: 1.5rem!important;
    }
}