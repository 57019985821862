.bg-xona {
    min-height: 70vh;
    background: #F5F5F5 !important;
    border-radius: 16px 16px 0px 0px;
    width: 98% !important;
}

.bg-form-xona {
    background: #FFFFFF;
    border-radius: 16px;
}

.form-input-xona{
    width: 770px !important;
    height: 44px !important;
    background: #F5F5F5 !important;
    border: 1px solid #DBDBDB !important;
    border-radius: 6px !important;
}
.title-xona{
    font-weight: 500;
    font-size: 36PX !important;
}
.button-end {
    margin-right: 5rem !important;
}
.xona-title{
    font-size: 20px !important;
    font-weight: 400;
}