.bg-katagoriya {
    min-height: 70vh;
    background: #F5F5F5 !important;
    border-radius: 16px 16px 0px 0px;
}

.bg-form-katagoroya {
    background: #FFFFFF;
    border-radius: 16px;
}

.form-input-ish-katagoriya {
    width: 770px !important;
    height: 44px !important;
    background: #F5F5F5 !important;
    border: 1px solid #DBDBDB !important;
    border-radius: 6px !important;
}

.ishlar-royhati .title {
    font-weight: 500 !important;
    font-size: 36px !important;
}

.button-end {
    margin-right: 5rem !important;
}

.bg-form-katagoroya .title {
    font-weight: 400;
    font-size: 20px !important;
}
.page-wq{
    width: 98% !important;
}